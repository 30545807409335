import { getClient } from '../lib/apiClient';

import {
  FETCH_ORGS,
  RECEIVE_ORGS,
  SENDING_ORG_INVITE,
  SEND_ORG_INVITE_ERROR,
  SEND_ORG_INVITE_SUCCESS,
  ACCEPTING_ORG_INVITE,
  ACCEPT_ORG_INVITE_SUCCESS,
  ACCEPT_ORG_INVITE_ERROR,
  FETCHING_ORG_ACTIVITY,
  RECEIVE_ORG_ACTIVITY,
  ORG_REMOVE_USER_ACCESS,
  ORG_REMOVE_USER_ACCESS_ERROR,
  RECEIVE_ORG_MEMBERS,
  SENDING_ORG_UPDATE,
  FETCHING_ORG_MEMBERS,
} from '../reducers/org';

export function getCurrentUserOrgs() {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_ORGS });
    const profile = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).get('/api/v1/orgs/current');
    dispatch({
      type: RECEIVE_ORGS,
      orgs: profile.data,
    });
  };
}

export function getOrgMembers(orgId, shortId) {
  return async (dispatch, getState) => {
    const org = getState().orgStore.orgs[shortId];
    if (!org?.memberInfoLoading) {
      dispatch({
        type: FETCHING_ORG_MEMBERS,
        shortId,
      });
      const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).get(`/api/v1/orgs/${orgId}/members`);
      dispatch({
        type: RECEIVE_ORG_MEMBERS,
        orgId,
        shortId,
        memberInfo: res.data.userInfo,
      });
    }
  };
}

export function getOrg(shortId, router) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_ORGS });
    const org = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/orgs/find',
      { filters: { shortId } });
    const noAccessibleOrgFound = org.data.length === 0;
    const notInvitationRoute = router.route.indexOf('invitation') === -1;
    if (noAccessibleOrgFound) {
      if (notInvitationRoute) {
        router.replace('/403');
      }
      // note: this freezes the org store in the loading state,
      // blocking further org find requests until it is reset by 
      // ACCEPT_ORG_INVITE_SUCCESS/ERROR, or by a full page navigation
      return
    }
    dispatch({
      type: RECEIVE_ORGS,
      orgs: org.data
    });
  };
}

export function updateOrg(orgUpdate) {
  return async (dispatch, getState) => {
    dispatch({
      type: SENDING_ORG_UPDATE
    });

    const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/orgs/update', orgUpdate);
    dispatch({
      type: RECEIVE_ORGS,
      orgs: [result.data]
    });
  }
}

export function sendInvite(invite) {
  return async (dispatch, getState) => {
    dispatch({
      type: SENDING_ORG_INVITE
    });
    try {
      const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/orgs/createInvite', invite);
      dispatch({
        type: SEND_ORG_INVITE_SUCCESS,
        orgs: [result.data]
      })
    } catch (error) {
      const errorMessage = error.response?.status || "ERROR";
      dispatch({ type: SEND_ORG_INVITE_ERROR, error: errorMessage });
    }
  }
}

export function acceptInvite(payload) {
  return async (dispatch, getState) => {
    dispatch({
      type: ACCEPTING_ORG_INVITE
    });
    try {
      await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/orgs/acceptInvite', payload);
      dispatch({
        type: ACCEPT_ORG_INVITE_SUCCESS
      })
    } catch (error) {
      const errorMessage = error.response?.status || "ERROR";
      dispatch({
        type: ACCEPT_ORG_INVITE_ERROR,
        error: errorMessage
      })
    }
  }
}

export function getCurrentOrgActivity(orgId, page, filters) {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCHING_ORG_ACTIVITY
    });
    const activiyPaging = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/activity_log/paging', {
      orgId,
      page,
      filters,
    });

    dispatch({
      type: RECEIVE_ORG_ACTIVITY,
      activiyPaging: activiyPaging.data
    });
  }
}

export function removeOrgUserAccess(removeOrgUserAccessRequest) {
  return async (dispatch, getState) => {
    dispatch({
      type: ORG_REMOVE_USER_ACCESS
    });
    try {
      const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/orgs/removeOrgUserAccess', removeOrgUserAccessRequest);
      dispatch({
        type: RECEIVE_ORGS,
        orgs: [result.data]
      })
    } catch (error) {
      const errorMessage = error.response?.status || "ERROR";
      dispatch({ type: ORG_REMOVE_USER_ACCESS_ERROR, error: errorMessage });
    }
  }
}
